<template>
  <x-dialog :proxy="createDistributeDialog">
    <el-tabs type="card" v-model="currentTab" v-if="createDistributeDialog.visible">
      <el-tab-pane label="发送给系统已有用户/用户组">
        <el-form ref="dynamicValidateForm" :model="dynamicValidateForm" :rules="rules" label-width="100px">
          <el-form-item prop="charge_money" label="面值">
            <el-input v-model="dynamicValidateForm.charge_money" :maxlength="8" style="width: 200px"></el-input>
          </el-form-item>

          <el-form-item label="短信通知" error="true">
            <el-switch active-color="#13ce66" v-model="dynamicValidateForm.is_send" />

            <div slot="error" style="line-height: 1">短信签名为：{{ shopInfo.sign_name || '暂无' }}</div>
          </el-form-item>

          <el-form-item label="选择用户">

            <div class="row no-gutters align-items-center transfer">
              <div class="col">
                <el-card shadow="hover">
                  <div slot="header" class="row no-gutters align-items-center">
                    <el-checkbox v-if="dynamicValidateForm.asUserNickname" :indeterminate="isIndeterminate1"
                      v-model="checkAll1" @change="handleCheckAllChange1">
                      全选
                    </el-checkbox>
                    <el-checkbox v-else :indeterminate="isIndeterminate" v-model="checkAll"
                      @change="handleCheckAllChange">
                      全选
                    </el-checkbox>

                    <div class="col"></div>

                    <el-checkbox class="mr-3" label="按手机号" v-model="dynamicValidateForm.asUserNickname" @change="$event => {
    dynamicValidateForm.asUserGroup = !$event
    dynamicValidateForm.type = 1
    this.getrightmsg = []
    this.checkAll = false;
    this.checkAll1 = false;
    handleCheckAllChange(false)
    handleCheckAllChange1(false)
  }" />
                    <el-checkbox label="按用户分组" v-model="dynamicValidateForm.asUserGroup" @change="$event => {
    dynamicValidateForm.asUserNickname = !$event
    dynamicValidateForm.type = 2
    this.getrightmsg = []
    this.checkAll = false;
    this.checkAll1 = false;
    handleCheckAllChange(false)
    handleCheckAllChange1(false)
  }" />
                  </div>

                  <template v-if="dynamicValidateForm.asUserGroup">
                    <el-checkbox-group v-model="checkedfz" @change="handleCheckedusersChange" style="height: 248px">
                      <div v-for="f in fz" class="row no-gutters align-items-center transfer-item">
                        <el-checkbox class="iblock" :label="f.value" :key="f.value">{{ f.name }}</el-checkbox>
                      </div>
                    </el-checkbox-group>
                  </template>

                  <template v-else>
                    <el-input placeholder="请输入手机号，按回车键搜索" size="small" style="margin-bottom: 8px"
                      v-model="param.keyword" @change="getkeyword">
                      <i class="el-icon-search el-input__icon" slot="prefix"></i>
                    </el-input>

                    <el-checkbox-group v-model="checkedusers" @change="handleCheckedusersChange1">
                      <virtual-list style="height: 200px; overflow-y: auto;" :data-key="'member_id'"
                        :data-sources="users" :data-component="itemComponent" />
                    </el-checkbox-group>
                  </template>
                </el-card>
              </div>

              <div class="col-auto transfer-center-icon">
                <el-button icon="el-icon-arrow-right" circle></el-button>
              </div>

              <div class="col">
                <el-card shadow="hover" :body-style="{ height: '264px' }">
                  <div slot="header" class="row no-gutters align-items-center">
                    <div class="col-auto">
                      已选用户：{{ getrightmsg.length }}人（排除重复）
                    </div>
                  </div>
                  <el-checkbox-group v-model="rightCheckedusers" @change="deleteMsg">
                  <virtual-list style="height: 248px; min-height: 200px; overflow-y: auto;" 
                    :data-key="'member_id'" :data-sources="getrightmsg" :data-component="checkedItemComponent" />
                  </el-checkbox-group>
                  <div class="el-form-item__error" v-if="formSelectUserError">请选择至少一个用户或用户分组！</div>
                </el-card>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="备注">
            <el-input v-model="dynamicValidateForm.reamrk" maxlength="100" placeholder="请输入备注信息，字数不超过100字" />
          </el-form-item>
        </el-form>
      </el-tab-pane>

      <el-tab-pane label="通过表格导入直接需发送的用户">
        <el-alert title="提示" type="error" :closable="false" style="margin-bottom: 16px">
          1. 使用表格，直接导入用户，并给用户充值 ，导入用户校验重复性，不校验用户是否已存在。<br>
          2. 假如导入文件里有错误的就会全部导入失败。
        </el-alert>

        <el-form label-width="100px">
          <el-form-item label="第一步">
            若无模板，则 <el-button type="text" @click="downTemplate">下载表格模板</el-button> 填写指定数据。
          </el-form-item>

          <el-form-item label="第二步">
            若已填好数据，则
            <upload-excel-component text="上传模板文件" type="text" size="default" :on-success="excelSuccess" />
            <span style="margin-left: -12px">。</span>
            <div v-if="importShow" style="line-height: 1; margin-left: -12px; color: #2155d5">
              （加载成功，本次共计导入{{ importNum || 0 }}条）
            </div>
          </el-form-item>

          <el-form-item label="短信通知" error="true">
            <el-switch active-color="#13ce66" v-model="sdimport.shopMemberVo.is_send" />

            <div slot="error" style="line-height: 1">短信签名为：{{ shopInfo.sign_name || '暂无' }}</div>
          </el-form-item>

          <el-form-item label="备注">
            <el-input v-model="sdimport.shopMemberVo.reamrk" maxlength="100" placeholder="请输入备注信息，字数不超过100字" />
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </x-dialog>
</template>

<script>
import * as API_Users from '@/api/users';
import { handleDownload } from '@/utils';
import UploadExcelComponent from '@/components/UploadExcel';
import { mapGetters } from 'vuex';
import { RegExp } from '@/../ui-utils';
import VirtualList from 'vue-virtual-scroll-list'
import Item from './xjffCheckItem'
import CheckedItem from './xjffCheckedItem'
import XDialog from '@/components/x-dialog/x-dialog';
import { $xDialog } from '@/components/x-dialog/dialog.proxy';

const fz = ['分组'];

export default {
  name: 'create-distribute',
  components: {
    XDialog,
    UploadExcelComponent,
    VirtualList
  },
  computed: {
    ...mapGetters(['shopInfo']),
  },
  data () {
    const checkChargeMoney = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('面值不能为空'));
      }
      if (!RegExp.money.test(value)) {
        callback(new Error('请输入正确的面值金额'));
      } else if (parseFloat(value) > 99999999) {
        callback(new Error('请输入小于99999999的面值金额'));
      } else {
        callback();
      }
    };
    return {
      formSelectUserError: false,
      refreshFn: () => {
      },
      currentTab: '0',
      createDistributeDialog: $xDialog.create({
        title: '充值分发',
        width: '800px',
        wrapperClass: 'recharge-distribution-dialog',
        beforeConfirm: () => {
          switch (this.currentTab) {
            case '0':
              return this.czok();
            case '1':
              return this.czok1();
          }
          return true;
        }
      }),
      sonflag: false,
      fileList: [
        {
          name: 'food.jpeg',
          url:
            'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
        },
      ],
      sdimport: {
        shopMemberVo: {
          is_send: 0,
          reamrk: '',
        },
      },
      activeName: 'tabOne',
      checkAll: false,
      checkAll1: false,
      importShow: false,
      importNum: 0,
      checkedusers: [],
      rightCheckedusers:[],
      checkedfz: [],
      users: [],
      itemComponent: Item,
      checkedItemComponent: CheckedItem,
      isIndeterminate: false,
      isIndeterminate1: false,
      dynamicValidateForm: {
        reamrk: '',
        is_send: false,
        type: 1,
        ids: [],
        charge_money: '',
        asUserNickname: true,
        asUserGroup: false,
      },
      input: '',
      fz: [],
      param: { keyword: '' },
      rightmsg: [],
      rightids: [],
      getrightmsg: [],
      getrightids: [],
      templateKey: ['mobile', 'charge_money'],
      templateHedaer: ['手机号', '金额'],
      rules: {
        charge_money: [
          {
            required: true,
            validator: checkChargeMoney,
            trigger: 'blur'
          },
        ],
      },
    };
  },

  watch: {
    activeName: function (val) {
      this.activeName = val;
    },
  },

  created () {
    this.getkeyword();
    let para = {
      page_no: 0,
      page_size: 0,
    };
    API_Users.getUserlist(para).then((res) => {
      res.data.forEach((item) => {
        item.name = item.group_name;
        item.value = item.group_id + ',' + item.group_name;
      });
      this.fz = res.data;
    });
    this.handleClick();
  },
  methods: {
    show (refreshFn) {
      this.refreshFn = refreshFn;
      // 初始化窗口数据
      // --- 初始化勾选状态
      this.checkAll = false;
      this.checkAll1 = false;
      this.handleCheckAllChange(false);
      this.handleCheckAllChange1(false);
      // --- 初始化右侧显示数据
      this.rightmsg = [];
      this.rightids = [];
      this.chuan();
      // --- 初始化表单数据，记录
      this.dynamicValidateForm = {
        reamrk: '',
        is_send: false,
        type: 1,
        ids: [],
        charge_money: '',
        asUserNickname: true,
        asUserGroup: false,
      };
      // --- 初始化el-tab-pane的位置位于第一个标签下
      this.currentTab = '0';
      this.createDistributeDialog.display();
    },
    excelSuccess ({ results }) {
      let resultsList = [];
      results.forEach((list) => {
        this.templateKey.forEach((item, index) => {
          list = JSON.parse(
            JSON.stringify(list).replace(this.templateHedaer[index], item)
          );
        });
        resultsList.push(list);
      });
      this.sdimport.shopMemberVo.member_charge_list = resultsList;
      this.importNum = resultsList.length;
      this.importShow = true;
    },
    downTemplate () {
      let modelData = [
        {
          mobile: '',
          charge_money: '',
          group_name: '',
        },
      ];
      handleDownload(
        modelData,
        this.templateHedaer,
        this.templateKey,
        '用户充值分发导入模板'
      );
    },

    handleClick (tab, event) {
      if (this.activeName === 'tabOne') {
        this.dynamicValidateForm.type = 1;
      } else {
        this.dynamicValidateForm.type = 2;
      }
      // this.dynamicValidateForm.type = tab.index + 1;
    },
    chuan () {
      if (this.rightmsg.length === 0) {
        this.getrightmsg = [];
        this.getrightids = [];
      }
      this.getrightmsg = this.rightmsg;
      this.getrightids = this.rightids;
    },
    getkeyword () {
      this.param.is_salesman = 0;
      API_Users.dpgetMemberList(this.param).then((res) => {
        res.forEach((item) => {
          item.value = `${item.member_id},${item.mobile},${item.nickname}`;
        });
        this.users = res;
      });
    },
    
    czok () {
      return new Promise(resolve => {
        this.$refs['dynamicValidateForm'].validate((valid) => {
          if (valid) {
            this.dynamicValidateForm.ids = this.getrightids;
            this.formSelectUserError = this.dynamicValidateForm.ids.length === 0;

            if (this.formSelectUserError) {
              return resolve(false);
            }

            if (this.dynamicValidateForm.is_send === true) {
              this.dynamicValidateForm.is_send = 1;
            } else {
              this.dynamicValidateForm.is_send = 0;
            }

            API_Users.dpAddCharge(
              this.dynamicValidateForm.ids,
              this.dynamicValidateForm
            ).then((res) => {
              this.refreshFn();
              resolve(true);
            });
          } else {
            resolve(false);
          }
        });
      });
    },
    czok1 () {
      return new Promise(resolve => {
        if (this.sdimport.shopMemberVo.is_send === true) {
          this.sdimport.shopMemberVo.is_send = 1;
        } else {
          this.sdimport.shopMemberVo.is_send = 0;
        }
        // this.sdimport.shopMemberVo.is_rechage = 1;
        API_Users.importChargeMoney(
          JSON.stringify(this.sdimport.shopMemberVo)
        ).then((res) => {
          if (res.result === 2) {
            this.$message.success(res.message);
            this.refreshFn();
            resolve(true);
          } else {
            this.$message.error(res.message);
            resolve(false);
          }
        });
      })
    },
    handleRemove (file, fileList) {
      console.log(file, fileList);
    },
    handlePreview (file) {
      console.log(file);
    },
    handleExceed (files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleCheckAllChange (val) {

      const arr = val ? this.fz : [];
      this.checkedfz = [];
      arr.map((item) => {
        this.checkedfz.push(item.value);
      });
      this.valueSplit(this.checkedfz);
      this.chuan();
      this.isIndeterminate = false;

    },
    handleCheckAllChange1 (val) {
      const arr = val ? this.users : [];
      this.checkedusers = [];
      arr.map((item) => {
        this.checkedusers.push(item.value);
      });
      this.valueSplit(this.checkedusers);
      this.isIndeterminate1 = false;
      this.chuan();
    },
    difference(arr1, arr2) {
    return arr1.filter(item => !arr2.includes(item));
    },
    deleteMsg: function (value) {//右侧选中取消事件
      if(this.dynamicValidateForm.asUserGroup){
        this.rightids=value.map(item=>item.split(',')[0])
      }else{
        const checked_id=value.map(item=>item.split(',')[0])//右侧选中的id
        const arrList=this.getrightmsg.map(item=>item.member_id)//右侧总数据
        const differenceArray = this.difference(arrList, checked_id)[0];
        this.checkedusers=this.checkedusers.filter(item=>item.split(',')[0]!==differenceArray)
        this.valueSplit(this.checkedusers);
      }
      this.chuan();

     
      // if (this.activeName === 'tabOne') {
      //   this.checkedusers.splice(index, 1);
      //   this.isIndeterminate1 = true;
      // } else {
      //   this.checkedfz.splice(index, 1);
      //   this.isIndeterminate = true;
      // }
      // this.rightmsg.splice(index, 1);
      // this.rightids.splice(index, 1);
      
      
    },
    async handleCheckedusersChange (value) {//用户分组选中分组change
      const ids=value.map(res=>res.split(',')[0])
      const dataList = await API_Users.getMemberByGroupId(ids.join())
      this.valueSplit(dataList);
      let arr = value;
      this.checkedfz = arr;
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.fz.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.fz.length;
      this.chuan();
    },
    handleCheckedusersChange1 (value) {//手机号选中change
      this.valueSplit(value);
      let arr = value;
      this.checkedusers = arr;
      let checkedCount = value.length;
      this.checkAll1 = checkedCount === this.users.length;
      this.isIndeterminate1 =
        checkedCount > 0 && checkedCount < this.users.length;
      this.chuan();
    },
    valueSplit (value) {
      this.rightids = [];
      this.rightmsg = [];
      this.rightCheckedusers=[];
      if(this.dynamicValidateForm.asUserGroup){//分组
        value.forEach((item) => {
        this.rightids.push(item.group_id);
        this.rightCheckedusers.push(`${item.member_id},${item.mobile},${item.nickname}`);
        this.rightmsg.push({
          'member_id': item.member_id,
          'mobile':item.mobile,
          'nickname': item.nickname,
          'value':`${item.member_id},${item.mobile},${item.nickname}`
        });
      });
      }else{//手机号
        
        value.forEach((item) => {
        let arr = (item + '').split(',');
        this.rightCheckedusers.push(`${arr[0]},${arr[1]},${arr[2]}`);
        this.rightids.push(arr[0]);
        this.rightmsg.push({
          'member_id': arr[0],
          'mobile':arr[1],
          'nickname': arr[2] !== 'null' ? arr[2] : '',
          'value':`${arr[0]},${arr[1]},${arr[2]}`
        });
      });
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
    removeDomain (item) {
      const index = this.dynamicValidateForm.domains.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1);
      }
    },
    addDomain () {
      this.dynamicValidateForm.domains.push({
        value: '',
        key: Date.now(),
      });
    },
  },
};
</script>

<style lang="scss">
.recharge-distribution-dialog {
  .transfer {
    .el-card__header {
      height: 40px;
      line-height: 40px;
      background: #f5f7fa;
      margin: 0;
      padding: 0 15px;
      color: #333;

      .el-checkbox {
        line-height: 1;
        margin: 0;
      }

      .row {
        height: 100%;
      }
    }

    .el-card__body {
      padding: 8px;

      .el-checkbox {
        line-height: 2;
        margin: 0;
      }

      .transfer-item {
        padding-left: 7px;
      }
    }

    .transfer-center-icon {
      .el-button {
        border: none;
        pointer-events: none;
      }
    }
  }

  .el-form-item.is-error {
    .el-card__body {
      .el-input__inner {
        border-color: #DCDFE6 !important;
      }
    }
  }
}
</style>
