<template>
  <div class="row no-gutters align-items-center transfer-item">
    <el-checkbox class="iblock" :label="source.value" :key="source.value">{{ `${source.mobile} (${source.nickname})`
      }}</el-checkbox>
  </div>
</template>

<script>
export default {
  name: 'item-component',
  props: {
    index: {
      type: Number
    },
    source: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>
